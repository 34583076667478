@font-face {
  font-family: 'meetplans';
  src: url('./fonts/meetplans.eot?1wbou2');
  src: url('./fonts/meetplans.eot?1wbou2#iefix') format('embedded-opentype'), url('./fonts/meetplans.ttf?1wbou2') format('truetype'),
    url('./fonts/meetplans.woff?1wbou2') format('woff'), url('./fonts/meetplans.svg?1wbou2#meetplans') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'meetplans' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.mp-kebab:before {
  content: "\e919";
}
.mp-meatballs:before {
  content: "\e91a";
}
.mp-bell:before {
  content: "\e900";
}
.mp-send:before {
  content: "\e901";
}
.mp-search:before {
  content: "\e902";
}
.mp-capture:before {
  content: "\e903";
}
.mp-download:before {
  content: "\e904";
}
.mp-plus:before {
  content: "\e905";
}
.mp-arrow-down-left:before {
  content: "\e906";
}
.mp-chevron-up:before {
  content: "\e907";
}
.mp-chevron-down:before {
  content: "\e908";
}
.mp-external-link:before {
  content: "\e909";
}
.mp-arrow-right:before {
  content: "\e90a";
}
.mp-arrow-left:before {
  content: "\e90b";
}
.mp-close:before {
  content: "\e90c";
}
.mp-phone:before {
  content: "\e90d";
}
.mp-date:before {
  content: "\e90e";
}
.mp-calendar-move:before {
  content: "\e90f";
}
.mp-calendar-add:before {
  content: "\e910";
}
.mp-calendar:before {
  content: "\e911";
}
.mp-chat:before {
  content: "\e912";
}
.mp-video:before {
  content: "\e913";
}
.mp-paper:before {
  content: "\e914";
}
.mp-check:before {
  content: "\e915";
}
.mp-camera:before {
  content: "\e916";
}
.mp-paperclip:before {
  content: "\e917";
}
.mp-person:before {
  content: "\e918";
}

i[class^='mp-'] {
  font-size: 16px;
}

@import '~bootstrap/scss/bootstrap';
@import './scss/antd.css';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,700&display=swap');

@import './scss/variables';
@import './scss/global';
@import './scss/mixin';
@import './scss/utils';

@import '../node_modules/react-datepicker/dist/react-datepicker.css';
@import './scss/datepicker';
@import './scss/fullcalendar';

// @import './old_variables';
@import './mixin';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  transition: all 0.2s ease-in;
}

html,
body,
#root,
.App {
  height: 100%;
}
li > a {
  text-decoration: none !important;
}
body {
  font-family: $font-family;
  font-size: $font-size-body-base;
  background-color: $color-bg-main;
  color: $color-text-primary;

  strong,
  .emphasis {
    font-weight: $font-weight-body-base-emphasis;
  }
}

.body-large {
  //styleName: Body-large;
  font-family: $font-family;
  font-size: $font-size-body-large;
  font-weight: $font-weight-body-large;
  line-height: $line-height-body-large;
  letter-spacing: 0em;
  text-align: left;

  strong,
  .emphasis {
    font-family: $font-family;
    font-size: $font-size-body-large-emphasis;
    font-weight: $font-weight-body-large-emphasis;
    line-height: $line-height-body-large-emphasis;
    letter-spacing: 0em;
    text-align: left;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: #91918d transparent;
}

*::-webkit-scrollbar {
  width: 6px;
}
*::-webkit-scrollbar-track {
  display: none;
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #91918d;
  border-radius: 20px;
  border: 3px;
}

.formInputUnit {
  margin-bottom: 24px;

  label {
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #31302d;
    display: block;
    margin-bottom: 6px;
  }

  input {
    height: 40px;
  }
}

input.form-control {
  border-radius: $button-border-radius;
  border: 1px solid $color-border-input-default;
  color: $color-text-input-label-default;
  background-color: $color-bg-input;
  height: 40px;
  font-size: $font-size-body-base;
}

input.form-control:hover {
  border: 1px solid $color-border-input-hover;
  color: $color-text-input-label-default;
}

input.form-control.error {
  border: 1px solid $color-border-input-error;
  color: $color-text-input-label-default;
}

.ant-select {
  margin-bottom: 6px;
}

select.error,
.ant-select.error > .ant-select-selector {
  border-color: $color-border-input-error;
  color: $color-text-input-label-default;
}

label {
  color: $color-text-input-label-default;
  font-family: $font-family-label;
  font-weight: $font-weight-label;
  font-size: $font-size-label;
  line-height: $line-height-label;
  margin-bottom: 6px;

  strong,
  .emphasis,
  &.form-label {
    font-weight: $font-weight-label-emphasis;
    line-height: $line-height-label-emphasis;
    font-size: $font-size-label-emphasis;
  }

  &.error {
    color: $color-text-input-label-error;
  }
}

.custom-checkbox .custom-control-indicator {
  background-color: #fff;
  border: 1px solid $color-border-checkbox-default !important;
  border-radius: $checkbox-border-radius;
  display: block;
  height: 16px !important;
  width: 16px !important;
}

/* When I remove background the icon goes back to that default size */
.custom-control-input:checked ~ .custom-control-indicator {
  background-color: $color-bg-checkbox-selected !important;
  position: relative;
  &:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    position: absolute;
    content: '';
  }
}

.btn,
button.btn,
button.ant-btn {
  border-width: 1px;
  border-style: solid;
  border-radius: $button-border-radius;
  padding: 7px 12px;
  font-weight: 600 !important;
  font-size: 0.87rem !important;
  line-height: 150% !important;
  overflow-wrap: anywhere;

  &.regular-standard {
    padding: 9.5px 16px;
  }

  &.regular-block {
    padding: 9.5px 25px;
  }

  &.small-standard {
    height: auto;
    padding: 7.5px 12px;
    i {
      margin-right: 6px;
      margin-left: 2px;
    }
  }

  &.icon-button {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    padding: 7px;
  }

  & + .btn {
    margin-left: 1rem;
  }

  a {
    text-decoration: none;
    &:active {
      text-decoration: none;
    }
    &:focus {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
    }
  }
}

a.btn {
  text-decoration: none;
}

.btn-primary,
button.ant-btn.ant-btn-primary {
  background-color: $color-bg-button-primary-default;
  border-color: $color-border-button-primary;
  color: $color-text-button-primary;
  &:hover {
    background-color: $color-bg-button-primary-hover;
    color: $color-text-button-primary;
  }
  &:active {
    background-color: $color-bg-button-primary-active;
    color: $color-text-button-primary;
  }
  a {
    color: $color-text-button-primary;
  }
}

.btn-basic,
button.ant-btn.ant-btn-default {
  background-color: $color-bg-button-basic-default;
  border-color: $color-border-button-basic;
  color: $color-text-button-basic;
  &:hover {
    background-color: $color-bg-button-basic-hover;
    color: $color-text-button-basic;
  }
  &:active {
    background-color: $color-bg-button-basic-active;
    color: $color-text-button-basic;
  }

  a {
    color: $color-text-button-basic;
  }
}

.btn-ghost {
  background-color: $color-bg-button-ghost-default;
  border-color: $color-border-button-ghost;
  color: $color-text-button-ghost;
  &:hover {
    background-color: $color-bg-button-ghost-hover;
    color: $color-text-button-ghost;
  }
  &:active {
    background-color: $color-bg-button-ghost-active;
    color: $color-text-button-ghost;
  }

  a {
    color: $color-text-button-ghost;
  }
}

.btn-danger {
  background-color: $color-bg-button-danger-default;
  border-color: $color-border-button-danger;
  color: $color-text-button-danger;
  &:hover {
    background-color: $color-bg-button-danger-hover;
    color: $color-text-button-danger;
  }
  &:active {
    background-color: $color-bg-button-danger-active;
    color: $color-text-button-danger;
  }

  a {
    color: $color-text-button-danger;
  }
}

a {
  @include mpLink;
}

// headers

h1 {
  font-family: $font-family-headline;
  line-height: $line-height-h1;
  font-size: $font-size-h1;
  font-weight: $font-weight-h1;
  color: #31302d;
}

h2 {
  font-family: $font-family-headline;
  line-height: $line-height-h2;
  font-size: $font-size-h2;
  font-weight: $font-weight-h2;
  color: #31302d;
}

h3 {
  font-family: $font-family-headline;
  line-height: $line-height-h3;
  font-size: $font-size-h3;
  font-weight: $font-weight-h3;
  color: #31302d;
}

ul.custom-tabs {
  border-bottom: 2px solid $color-line-tabrow;
  font-size: 1rem;
  list-style-type: none;

  li {
    color: $color-text-button-primary;
    position: relative;
    padding: 0;
    margin-right: $inner-tab-menu-box-margin;
    line-height: 24px;

    a {
      color: $color-text-button-primary;
      display: inline-block;
      padding: 0 $inner-tab-menu-padding 6px $inner-tab-menu-padding;
      z-index: 1;
    }
    &:hover {
      text-decoration: none;
    }
    &.active {
      &::after {
        background-color: $color-border-button-primary;
        transform: scaleX(1);
      }
    }
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      background-color: $color-text-button-primary;
      transform: scaleX(0);
      bottom: -2px;
      left: 0;
      width: 100%;
      transition: all 0.2s ease;
    }
    &:hover::after {
      transform: scaleX(1);
    }
  }
}

ul.meetplans-list {
  li {
    color: $color-text-primary;
    padding: 0 0 0 1.5rem;
    line-height: 42px;
    cursor: pointer;

    &:hover {
      background-color: #f5f4f0; // gray-warm-4
    }
    &.selected {
      position: relative;
      background-color: #eef3de; // TODO: move this to design-tokens - lime-5
      font-family: $font-family;
      font-size: $font-size-body-large-emphasis;
      font-weight: $font-weight-body-large-emphasis;
      // line-height: $line-height-body-large-emphasis;
      text-align: left;
    }
    &.online::before {
      content: '⬤ ';
      position: absolute;
      left: 0.5rem;
      font-size: 8px;
      color: $color-list-circle;
    }
  }
}

ul.ant-timeline {
  li {
    color: $color-text-primary;
  }
}

.error-input {
  border-color: $color-border-input-error !important;
}

.error-text {
  color: $color-text-input-label-error;
}

table {
  tr {
    td {
      padding: 5px;
    }
  }
}

.btn-light {
  border: 1px solid $color-border-button-basic;
  color: black;
}

.list-group.removable-item-list {
  margin-top: 12px;

  button {
    .deleteItem {
      display: none;
      float: right;
      cursor: pointer;
    }

    &:hover {
      .deleteItem {
        display: block;
        color: black;
      }
    }

    &:focus {
      outline-color: #c8e7fd;
      outline-width: 0px;
    }
  }
}

i.dropdown-icon {
  content: url('./dropdown-arrow.svg');
}

/* Tasks Checkbox */
ul.auf-list,
.openTasks {
  input[type='checkbox'] {
    position: relative;
    top: 2px;
    box-sizing: content-box;
    width: 14px;
    height: 14px;
    margin: 0 5px 0 0;
    cursor: pointer;
    -webkit-appearance: none;
    border-radius: $checkbox-border-radius;
    border: 1px solid $color-border-checkbox-default;
  }

  input[type='checkbox']:focus {
    outline-color: #c8e7fd;
    box-shadow: 0px 0px 0px 2px #c8e7fd;
    border-radius: 2px;
  }

  input[type='checkbox']:hover {
    border: 1px solid $color-border-checkbox-hover;
    border-radius: 2px;
  }

  input[type='checkbox']:before {
    content: '';
    display: block;
    // background-color: $color-bg-checkbox-selected-crossed;
  }

  input[type='checkbox']:checked {
    background-color: $color-bg-checkbox-selected-crossed;
  }

  input[type='checkbox']:checked:before {
    width: 6px;
    height: 10px;
    margin: 0px 4px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
  }
}

.react-draggable {
  transition: none;
}

/** Ant Table **/
.ant-table-thead > tr > th {
  background-color: #e4f3ff;
}

.ant-table-thead > tr > th.ant-table-column-sort {
  background-color: #c8e7fc;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: #91cdfc;
  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    color: #0c72d1;
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: #00203f;
  }
}

.ant-table-filter-trigger-container-open,
.ant-table-filter-trigger-container:hover,
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
  background-color: #91cdfc;
}

.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
  background-color: #56B2FE;

  .ant-table-filter-trigger {
    color: #0c72d1;
  }
}

//  .ant-table-filter-trigger

td.ant-table-column-sort {
  // background-color: #F9F9F9;
  // background-color: #fafafa;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #f9f9f9;
}

@import './scss/variables';

.App {
  button.fc-button {
    border-width: 1px;
    border-style: solid;
    border-radius: $button-border-radius;
    padding: 7px 12px;
    font-weight: 600;
    font-size: 0.87rem;
    line-height: 150%;
    overflow-wrap: anywhere;
  }
}

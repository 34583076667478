// generate utitlity classes with rem value
$spaceamounts: (
  '0': 0,
  '4': 0.25,
  '8': 0.5,
  '12': 0.75,
  '16': 1,
  '20': 1.25,
  '24': 1.5,
  '32': 2,
  '40': 2.5,
  '48': 3,
  '60': 3.75,
  '75': 4.68,
  '100': 6.25,
);
$sides: (
  '': 'all',
  't': 'top',
  'b': 'bottom',
  'l': 'left',
  'r': 'right',
);

@each $space, $name in $spaceamounts {
  @each $prefix, $value in $sides {
    $property: if($prefix == '', '', -#{$value});
    .m#{$prefix}#{$space} {
      margin#{$property}: #{$name}rem;
    }
    .p#{$prefix}#{$space} {
      padding#{$property}: #{$name}rem;
    }
  }
}

@mixin mpLink {
  color: $color-text-link;
  font-size: $font-size-body-base;
  text-decoration: $font-style-link;
  cursor: pointer;
}

@import './scss/variables';
@import './scss/mixin';

.navbar-brand {
  img {
    width: 100px;
  }
}

Nav {
  background-color: $color-line-tabrow;
  color: $color-text-tab;
}

.navbar-nav {
  margin-left: 2rem;
}
.navbar-nav .active > .nav-link {
  color: $color-indicatorline-tab-active;
}

.navbar-nav.tabs {
  line-height: $line-height-body-large-emphasis;

  a {
    padding: 0 1rem;
    margin-top: 6px;
    color: $color-text-tab;
    text-decoration: none;
    font-size: $font-size-button-regular;
    user-select: none;

    &.active {
      font-weight: $font-weight-body-large-emphasis;

      &:hover {
        text-shadow: none;
      }

      svg {
        color: $color-indicatorline-tab-active;
      }
    }

    &:hover {
      color: $color-indicatorline-tab-hover;
      text-shadow: -0.03ex 0 black, 0.03ex 0 black;
      // font-weight: $font-weight-body-large-emphasis;
    }

    &:active {
      color: $color-indicatorline-tab-active;
    }

    svg {
      margin-right: 8px;
    }
  }
}

.navbar-nav.pull-right {
  margin-left: auto;
}

.search-form {
  display: flex;
}

.nav-items {
  width: 100%;

  .nav-top-row {
    display: flex;
    justify-content: space-between;
    .salute-and-logout {
      display: flex;
      align-items: center;

      button {
        margin-left: 12px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        i.mp-arrow-right {
          margin-right: 6px;
        }
      }
    }
  }
  .nav-bottom-row {
    display: flex;
  }
}

/*
Search bar with an icon
*/

.input-group {
  input.form-control {
    border-right: 0px;
  }
  .input-group-append {
    color: $color-text-input-label-default;
    border: 0px;
  }

  .input-group-text {
    border-radius: $button-border-radius;
    background-color: $color-bg-input;
    color: $color-text-input-label-default;
    border: 1px solid $color-border-input-default;
  }
}

.main-container {
  padding: $main-content-padding;
  height: calc(100% - 158px);
}

.expertClockAlignment {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

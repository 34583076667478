@import './scss/variables';

// Boxes

.box-container {
  display: flex;
  flex-direction: column;

  background-color: $color-bg-box-header;
  /* Gray-warm/gray-warm-10 */
  border: 1px solid $color-border-box;
  box-sizing: border-box;
  border-radius: $button-border-radius;

  &.lime {
    background-color: $color-bg-box-header-active-lime;
  }

  &.blue {
    background-color: $color-bg-box-header-active-blue;
  }

  &.teal {
    background-color: $color-bg-box-header-active-teal;
  }

  &.gold {
    background-color: $color-bg-box-header-active-gold;
  }

  &.danger {
    background-color: $color-bg-box-header-danger;
  }

  .box-title {
    font-weight: $font-weight-body-base-emphasis;
    font-size: $font-size-body-base;
    line-height: $line-height-body-large-emphasis;
    padding: $margin-panel;
    display: flex;
    justify-content: space-between;
    height: 45px;
  }

  .box-content {
    // color: $color-text-secondary;
    font-size: $font-size-body-base;
    background-color: $color-bg-box-content;
    box-sizing: border-box;
    padding: $grid-margin 0;
    border-radius: $button-border-radius;
    border-top: 1px solid $color-border-box;

    max-height: 50rem;
    overflow-y: scroll;
    overflow-x: hidden;

    &.inner-pad {
      padding: $margin-panel;
    }

    &.inner-pad-large {
      padding: 24px;
      overflow-y: auto;
      max-height: none;
    }

    &.multi-child-boxes {
      display: flex;
      flex-direction: row;
      padding: 0;

      overflow-y: auto;
      max-height: none;
    }

    &.no-padding {
      padding: 0;
      overflow-y: auto;
    }
  }
}

// custom utility classes

.bold {
  font-weight: 600;
}
.text-block {
  margin-bottom: 2rem;
}

.rounded-badge {
  font-size: 1rem !important;
  border-radius: 18px;
  padding: 10px 20px !important;
  & + .rounded-badge {
    margin-left: 10px;
  }
}

.reverse {
  transform: rotate(180deg) !important;
  display: inline-block;
}

.authlayout-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  height: 100%;

  .auth-body {
    flex: 1 0 auto;
  }

  img {
    max-width: 400px;
    max-height: 200px;
    margin-bottom: 40px;
  }

  .footer { 
    color: #5f5e5b;
    text-align: center;
    margin-bottom: 30px;

    flex-shrink: 0;
  }

  .main-col {
    min-width: 450px;
    transition: width 2s;
  }

}

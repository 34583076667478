
$color-indicatorline-tab-default: rgb(0, 0, 0);
$color-border-checkbox-selected-crossed: rgb(115, 114, 111);
$color-text-checkbox-label: rgb(49, 48, 45);
$color-bg-button-danger-default: rgb(250, 141, 121);
$color-bg-danger: rgb(255, 237, 233);
$color-border-checkbox-hover: rgb(49, 48, 45);
$color-bg-available-dates: rgb(153, 181, 89);
$color-border-radio-selected: rgb(49, 48, 45);
$color-text-info: rgb(7, 90, 179);
$color-text-selectitem: rgb(49, 48, 45);
$color-border-info: rgb(200, 231, 252);
$color-text-box-header: rgb(49, 48, 45);
$color-bg-success: rgb(238, 243, 222);
$color-text-input-label-default: rgb(49, 48, 45);
$color-bg-button-danger-active: rgb(255, 219, 211);
$color-border-checkbox-default: rgb(115, 114, 111);
$color-text-input-help-default: rgb(115, 114, 111);
$color-bg-distinguish: rgb(250, 249, 247);
$color-indicatorline-tab-active: rgb(12, 114, 209);
$color-border-button-danger: rgb(246, 94, 74);
$color-bg-checkbox-selected: rgb(49, 48, 45);
$color-select-arrow-active: rgb(12, 114, 209);
$color-text-button-danger: rgb(49, 48, 45);
$color-text-button-basic: rgb(49, 48, 45);
$color-border-input-hover: rgb(49, 48, 45);
$color-border-button-basic: rgb(199, 198, 195);
$color-text-button-ghost: rgb(49, 48, 45);
$color-bg-button-ghost-hover: rgb(250, 249, 247);
$color-bg-checbox: rgb(255, 255, 255);
$color-bg-button-primary-active: rgb(200, 231, 252);
$color-bg-button-basic-hover: rgb(242, 241, 237);
$color-bg-radio-selected: rgb(255, 255, 255);
$color-border-button-ghost: rgb(199, 198, 195);
$color-bg-info: rgb(227, 243, 255);
$color-border-success: rgb(221, 231, 193);
$color-text-radio-label: rgb(49, 48, 45);
$color-bg-input: rgb(255, 255, 255);
$color-text-primary: rgb(49, 48, 45);
$color-text-input-label-error: rgb(181, 37, 31);
$color-text-success: rgb(74, 103, 9);
$color-bg-box-header: rgb(242, 241, 237);
$color-icon-checkbox-selected-crossed: rgb(255, 255, 255);
$color-border-distinguish: rgb(227, 226, 223);
$color-border-input-default: rgb(115, 114, 111);
$color-border-checkbox-selected: rgb(49, 48, 45);
$color-text-input-help-error: rgb(181, 37, 31);
$color-bg-date-picker: rgb(227, 243, 255);
$color-bg-button-ghost-default: rgba(0, 0, 0, 0);
$color-focus-ring: rgb(200, 231, 252);
$color-bg-box-header-active-lime: rgb(238, 243, 222);
$color-bg-selectitem-hover: rgb(247, 246, 243);
$color-bg-box-header-danger: rgb(255, 237, 233);
$color-text-link: rgb(7, 90, 179);
$color-bg-button-basic-default: rgb(227, 226, 223);
$color-border-selectmenu: rgb(199, 198, 195);
$color-border-expert-search-avatar: rgb(7, 90, 179);
$color-border-box: rgb(227, 226, 223);
$color-line-tabrow: rgb(227, 226, 223);
$color-bg-selectitem-default: rgb(255, 255, 255);
$color-text-input: rgb(49, 48, 45);
$color-bg-box-header-active-blue: rgb(227, 243, 255);
$color-border-input-error: rgb(181, 37, 31);
$color-bg-box-header-active-teal: rgb(222, 245, 248);
$color-border-date-picker: rgb(12, 150, 238);
$color-bg-main: rgb(247, 246, 243);
$color-icon-radio-selected: rgb(49, 48, 45);
$color-bg-button-primary-hover: rgb(145, 205, 252);
$color-bg-button-basic-active: rgb(253, 252, 250);
$color-bg-button-ghost-active: rgb(253, 252, 250);
$color-border-radio-hover: rgb(49, 48, 45);
$color-bg-box-content: rgb(253, 252, 250);
$color-bg-selectmenu: rgb(255, 255, 255);
$color-border-input-active: rgb(49, 48, 45);
$color-bg-checkbox-selected-crossed: rgb(115, 114, 111);
$color-text-danger: rgb(181, 37, 31);
$color-border-danger: rgb(255, 219, 211);
$color-select-arrow-hover: rgb(49, 48, 45);
$color-bg-box-header-active-gold: rgb(255, 241, 183);
$color-icon-checkbox-selected: rgb(255, 255, 255);
$color-indicatorline-tab-hover: rgb(49, 48, 45);
$color-bg-button-danger-hover: rgb(253, 181, 167);
$color-bg-button-primary-default: rgb(86, 178, 254);
$color-bg-expert-search-hover: rgb(227, 243, 255);
$color-text-button-primary: rgb(49, 48, 45);
$color-text-placeholder: rgb(115, 114, 111);
$color-bg-radio: rgb(255, 255, 255);
$color-border-radio-default: rgb(115, 114, 111);
$color-text-secondary: rgb(115, 114, 111);
$color-border-button-primary: rgb(12, 150, 238);
$color-select-arrow-default: rgb(115, 114, 111);
$color-text-tab: rgb(49, 48, 45);
/* Border radius for inputs. */
$button-border-radius: 8px;
$checkbox-border-radius: 2px;
$panel-border-radius: 8px;
$color-list-circle: rgb(91, 128, 4);
/* Default font family. */
$font-family: 'Noto Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
/* Font family used for headlines. */
$font-family-headline: 'Noto Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
/* Font family used for body text. */
$font-family-body: 'Noto Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
/* Font family used for overline text. */
$font-family-overline: 'Noto Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
/* Font family used for label text. */
$font-family-label: 'Noto Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
/* Font family used for code in markdown. */
$font-family-monospace: 'Noto Sans Mono', Consolas, Menlo, Monaco, Courier, monospace;
/* Text for labels. */
$font-size-label-emphasis: 12px;
/* Text for labels. */
$font-size-label: 12px;
/* Large body text. */
$font-size-body-large-emphasis: 16px;
/* Base body text. */
$font-size-body-base: 14px;
/* Text for regular buttons. */
$font-size-button-regular: 16px;
/* H1 */
$font-size-h1: 24px;
/* H2 */
$font-size-h2: 20px;
/* H3 */
$font-size-h3: 16px;
/* Text for small buttons. */
$font-size-button-small: 14px;
/* Large body text. */
$font-size-body-large: 16px;
/* Used for H1. */
$font-weight-h1: 600;
/* Used for H2. */
$font-weight-h2: 600;
/* Used for H3. */
$font-weight-h3: 600;
/* Used sparingly for emphasized text within large body. */
$font-weight-body-large-emphasis: 600;
/* Used sparingly for emphasized text within base body. */
$font-weight-body-base-emphasis: 600;
/* Most all body. */
$font-weight-body-base: 400;
/* body large. */
$font-weight-body-large: 400;
/* Used for buttons. */
$font-weight-button: 600;
/* Used for emphasized label text. */
$font-weight-label-emphasis: 600;
/* Used for regular label text. */
$font-weight-label: 400;
$columns: 12;
$grid-margin: 16px;
$grid-gutter: 16px;
/* Text for labels. */
$line-height-label-emphasis: 1.4;
/* Text for labels. */
$line-height-label: 1.4;
/* Large body text. */
$line-height-body-large-emphasis: 1.5;
/* Text for regular buttons. */
$line-height-button-regular: 1.4;
/* Base body text. */
$line-height-body-base: 1.5;
/* Main headlines. */
$line-height-h1: 1.2;
/* Secondary headline. */
$line-height-h2: 1.3;
/* 3rd Headline */
$line-height-h3: 1.4;
/* Text for small buttons. */
$line-height-button-small: 1.4;
/* Large body text. */
$line-height-body-large: 1.5;
/* links */
$font-style-link: underline;
/* Padding between icon and text inside the button. */
$padding-button-icon-text: 4px;
$margin-panel: 12px;
$main-content-padding: 24px;
/* Padding between the checkbox and the label */
$form-check-input-gutter: 8px;
$inner-tab-menu-margin: 16px;
$inner-tab-menu-box-margin: 24px;
$inner-tab-menu-padding: 8px;
$inner-tab-menu-line-padding: 20px;
/* Padding between button border and text/icon on the right and on the left side. */
$padding-button-right-left: 4px;
$form-check-inline-margin-x: 8px;

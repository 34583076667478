.App, .ant-modal-root {
  .react-datepicker {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }

  .react-datepicker__header {
    background: $color-bg-input !important;
  }
  .react-datepicker__day {
    outline: none !important;
    &:hover {
      border-radius: 1rem !important;
      background-color: $color-border-box !important;
    }
  }
  .react-datepicker__day-name {
    color: $color-text-secondary !important;
  }

  .react-datepicker__day--selected {
    background-color: $color-bg-date-picker !important;
    color: $color-text-info !important;
    border-radius: 1rem !important;
    border: 1px solid $color-border-date-picker !important;
  }

  .react-datepicker__day--keyboard-selected {
    border-radius: 1rem !important;
    background-color: $color-border-box !important;
  }
  .react-datepicker__current-month {
    color: $color-text-info !important;
    background-color: $color-bg-date-picker !important;
    border: 1px solid $color-border-date-picker !important;
    margin-right: 20% !important;
    margin-left: 20% !important;
    border-radius: 1rem !important;
    font-weight: 400 !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
  .react-datepicker-time__input {
    margin-left: 0 !important;
  }

  .react-datepicker__input-time-container {
    margin: 5px 12px 10px 12px !important;
    display: flex !important;
    justify-content: center !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    margin-top: 8px !important;
    margin-right: 10px !important;
  }
  .react-datepicker-time__caption {
    display: block !important;
  }
}
